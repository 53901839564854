<template>
  <table class="table mb-0 table-center">
    <caption class="text-center" v-if="!loadingTop && !userPool && userWatcherId">По Вашему <b>Watcher link</b> недостаточно данных</caption>
    <thead class="bg-grey">
      <tr>
        <th scope="col" class="text-center">{{ $t("rating.chart") }}</th>
        <th scope="col" class="text-center">{{ $t("rating.rating") }}</th>
        <th scope="col" class="text-center">{{ $t("rating.pool") }}</th>
        <th scope="col" class="text-center">{{ $t("rating.profitability") }}</th>
        <th scope="col" class="text-center" v-if="tableSize == 'full'">{{ $t("rating.hashrate") }}</th>
        <th scope="col" class="text-center" v-if="tableSize == 'full'">{{ $t("rating.income") }}</th>
        <th scope="col" class="text-center" v-if="userPool">{{ $t("rating.possible_profit") }}</th>
      </tr>
    </thead>
    <tbody v-if="pools.length > 0">
      <tr class="text-center"
        v-for="(pool, p_i) in pools" 
        :id="'pool-' + pool.pool_id" 
        :key="pool.pool_id"
        :class="{ 'table-row': !loadingTop, 'odd': p_i % 2 != 0, disabled: loadingTop, 'table-row-user': pool.is_user }"
      >
        <td class="text-center">
          <b-form-checkbox v-if="!pool.is_user" v-model="selectedPools" :value="pool.pool_id"></b-form-checkbox>
          <span v-else>
            <x-circle-icon size="18"  class="remove-icon" @click="removeUserWatcher"></x-circle-icon>
            <b>You</b>
          </span>
        </td>
        <td class="text-center">{{ p_i + 1 }}</td>
        <td>
          <a :href="pool.pool_url" target="_blank" v-if="!loadingTop">
            <img :src="'images/pool_logo/' + pool.pool_favicon" class="favicon">
            {{ pool.pool_name }}
          </a>
          <span class="text-muted text-small">{{ topHashInPersent(pool) }}%</span>
        </td>
        <td class="text-center"><b>{{ pool.avr_pay_rate.toFixed(3) }}</b></td>
        <td class="text-center" v-if="tableSize == 'full'">{{ pool.hashrate_avr.toFixed(2) }}</td>
        <td class="text-center" v-if="tableSize == 'full'">{{ pool.income_avr.toFixed(8) }}</td>
        <td class="text-center" v-if="userPool">
          <span :class="{'good-profit': pool.profit > 0 && !pool.is_user, 'bad-profit': pool.profit < 0 && !pool.is_user}">
            <span v-if="pool.profit > 0 && !pool.is_user">+</span>
            <span>{{ pool.profit.toFixed(8) }}</span>
          </span>
        </td>
      </tr>
    </tbody>
    <tbody v-else>
      <td :colspan="tableSize == 'small' ? 4 : 7" class="text-center">
        <b>
          <p class="text-muted my-0">{{ $t("rating.nodata1") }}</p>
          <p class="text-muted my-0">{{ $t("rating.nodata2") }}</p>
          <p class="text-muted mb-0 mt-2">{{ $t("rating.nodata3") }}</p>
        </b>
      </td>
    </tbody>
  </table>
</template>

<script>
import { ArrowUpIcon, RefreshCcwIcon, HelpCircleIcon, XCircleIcon } from 'vue-feather-icons'
export default {
  name: 'rankTable',
  components: {
    XCircleIcon
  },
  props: ['tableSize', 'loadingTop', 'userPool', 'userWatcherId', 'pools', 'selectedPoolsId'],
  data () {
    return {
      selectedPools: []
    }
  },
  mounted () {
    this.selectedPools = this.selectedPoolsId
  },
  watch: {
    selectedPools (value) {
      this.$emit('selectPools', value)
    },
    selectedPoolsId (value) {
      this.selectedPools = value
    }
  },
  computed: {
    maxRate () {
			if (this.userPool) return +this.userPool.avr_pay_rate
			else return this.pools ? Math.max.apply(null, this.pools.map(p => +p.avr_pay_rate)) : 0
		}
  },
  methods: {
    topHashInPersent (pool) {
			return (+pool.avr_pay_rate / this.maxRate * 100).toFixed(2)
		},
    removeUserWatcher () {
      this.$emit('removeUserWatcher')
    }
  }

}
</script>

<style>
  .favicon {
    width: 18px;
    padding-right: 2px;
  }
  .odd {
    background-color: #ededed;
  }
  .table-row:hover {
    background-color: #a3acc9 !important ;
  }
	.table-row-user {
		background-color: #bdc4ff !important
	}
  .remove-icon {
		height: 20px;
		color: red;
		cursor: pointer;
		margin-right: 8px;
	}
  .text-small {
		font-size: 0.6rem;
	}
  .good-profit {
		color: green;
	}
	.bad-profit {
		color: red;
	}
</style>