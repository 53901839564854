<template>
  <div class="container">
    <div class="row justify-content-center">
			<div class="col-12 text-center">
				<div class="section-title">
					<h4 class="title mb-4">{{ $t("faq.title") }}</h4>
				</div>
			</div>
		</div>
    <div class="row">
      <div class="col-md-6 col-12">
        <div class="media">
          <help-circle-icon
            class="fea icon-ex-md text-primary mr-2 mt-2"
          ></help-circle-icon>
          <div class="media-body">
            <b-button variant="none" class="w-100 m-0 p-0 d-flex justify-content-between"  @click="q1visible = !q1visible">
              <h5 class="text-left mb-0 mw-80">
                {{ $t('faq.question1.title') }}
              </h5>
              <chevron-down-icon v-if="!q1visible" class="text-primary ml-2 my-auto"/>
              <chevron-up-icon v-if="q1visible" class="text-primary ml-2 my-auto"/>
            </b-button>
            <b-collapse v-model="q1visible">
              <p class="answer text-muted mb-0">
                <p class="answer text-muted mb-0">{{ $t('faq.question1.p1') }}</p>
                <p class="answer text-muted mb-0"><b>avr. Hashrate</b>{{ $t('faq.question1.p2') }}</p>
                <p class="answer text-muted mb-0"><b>avr. Payment</b>{{ $t('faq.question1.p3') }}</p>
                <p class="answer text-muted mb-0">{{ $t('faq.question1.p4') }} <b>avr. Payment / avr. Hashrate = Real Payrate</b></p>
                <p class="answer text-muted mb-2"><b>Real Payrate</b>{{ $t('faq.question1.p5') }}</p>
                <p class="answer text-muted mb-0">{{ $t('faq.question1.p6') }}</p>
              </p>
            </b-collapse>
          </div>
        </div>
      </div>
      <!--end col-->

      <div class="col-md-6 col-12">
        <div class="media">
          <help-circle-icon
            class="fea icon-ex-md text-primary mr-2 mt-2"
          ></help-circle-icon>
          <div class="media-body">
            <b-button variant="none" class="w-100 m-0 p-0 d-flex justify-content-between" @click="q2visible = !q2visible">
              <h5 class="mb-0 text-left mw-80">
                {{ $t('faq.question2.title') }}
              </h5>
              <chevron-down-icon v-if="!q2visible" class="text-primary my-auto"/>
              <chevron-up-icon v-if="q2visible" class="text-primary my-auto"/>
            </b-button>
            <b-collapse v-model="q2visible">
              <p class="answer text-muted mb-0">
                {{ $t('faq.question2.p1') }}<br>
                {{ $t('faq.question2.p2') }}<br>
                {{ $t('faq.question2.p3') }}<br>
                {{ $t('faq.question2.p4') }}
              </p>
            </b-collapse>
          </div>
        </div>
      </div>
      <!--end col-->

      <div class="col-md-6 col-12 mt-4 pt-2">
        <div class="media">
          <help-circle-icon
            class="fea icon-ex-md text-primary mr-2 mt-2"
          ></help-circle-icon>
          <div class="media-body">
            <b-button variant="none" class="w-100 m-0 p-0 d-flex justify-content-between"  @click="q3visible = !q3visible">
              <h5 class="mb-0 text-left mw-80">
                {{ $t('faq.question3.title') }}<span class="text-primary"><br>Watcher link</span>.
              </h5>
              <chevron-down-icon v-if="!q3visible"  class="text-primary ml-2 my-auto"/>
              <chevron-up-icon v-if="q3visible" class="text-primary ml-2 my-auto"/>
            </b-button>
            <b-collapse v-model="q3visible">
              <p class="answer text-muted mb-0">
                {{ $t('faq.question3.p1') }}
              </p>
            </b-collapse>
          </div>
        </div>
      </div>
      <!--end col-->

      <div class="col-md-6 col-12 mt-4 pt-2">
        <div class="media">
          <help-circle-icon
            class="fea icon-ex-md text-primary mr-2 mt-2"
          ></help-circle-icon>
          <div class="media-body">
            <b-button variant="none" class="w-100 m-0 p-0 d-flex justify-content-between"  @click="q4visible = !q4visible">
              <h5 class="mb-0 text-left mw-80">
                {{ $t('faq.question4.title') }}
              </h5>
              <chevron-down-icon v-if="!q4visible" class="text-primary ml-2 my-auto"/>
              <chevron-up-icon v-if="q4visible" class="text-primary ml-2 my-auto"/>
            </b-button>
            <b-collapse v-model="q4visible">
              <p class="answer text-muted mb-0">
                {{ $t('faq.question4.p1') }}
              </p>
            </b-collapse>
          </div>
        </div>
      </div>
      <!--end col-->
    </div>
  </div>
</template>

<script>
import { HelpCircleIcon, CornerRightDownIcon, CornerRightUpIcon, ChevronUpIcon, ChevronDownIcon } from 'vue-feather-icons'
import { isMobile } from "../utils"

export default {
  name: "FAQ",
  components: {
    HelpCircleIcon,
    CornerRightDownIcon,
    CornerRightUpIcon,
    ChevronUpIcon,
    ChevronDownIcon
  },
  data () {
    return {
      q1visible: true,
      q2visible: true,
      q3visible: true,
      q4visible: true
    }
  },
  mounted () {
    if (isMobile()) {
      q1visible = false
      q2visible = false
      q3visible = false
      q4visible = false
    }
  }
}
</script>

<style scoped>
.mw-80 {
  max-width: 80%;
}
</style>