<template>
  <div class="container">
    <div class="row justify-content-center">
			<div class="col-12 text-center">
				<div class="section-title">
					<h4 class="title mb-4">О нас</h4>
				</div>
			</div>
		</div>
    <div class="row">
      <p>
        Мы "описание нас"!
      </p>
      <p>
        Для получения актуальной информации о рейтинге пулов можно подписать на нашего 
        <a target="_blank" href="https://t.me/rating_pool_test_bot">telegram bot</a>, где можно пулачать рейтинг по расписанию, также добавить свой вотчер и получать рейтинг по расписанию с Вашим вотчером!
      </p>
      <p>
        Для связи с нами вы можете написать на почту: <a href="mailto:info@poools.top">info@poools.top</a>, или использовать нашего <a target="_blank" href="https://t.me/rating_pool_test_bot">telegram bot</a>
      </p>
    </div>
    <div class="row">
			Адрес кошелька для пожертвования: хххххххххххххххх
		</div>
  </div>
</template>

<script>
export default {
  name: "About"
}
</script>

<style>

</style>