<template>
  <b-card class="m-auto"
    title="Release 1.0"
    :footer="$t('roadmap.completed', {persent: 100})" 
  >
    <hr />
    <b-card-text>
      <ul>
        <li>{{ $t('roadmap.release1.p1') }}</li>
        <li>{{ $t('roadmap.release1.p2') }}</li>
        <li>{{ $t('roadmap.release1.p3') }}</li>
        <li>{{ $t('roadmap.release1.p4') }}</li>
        <li>{{ $t('roadmap.release1.p5') }}</li>
      </ul>
    </b-card-text>
  </b-card>
</template>

<script>
export default {
  name: "Release1"
}
</script>

<style>

</style>