<template>
  <div>
    <canvas id="incomeChart"></canvas>
    <div class="row justify-content-around mt-1">
      <b-form-checkbox switch v-model="showDifficulty" style="line-height: 1.3;">{{
          $t("chart.difficulty")
        }}
      </b-form-checkbox>
      <span>
        <span class="mr-2" style="align-self: center;">{{ $t("chart.scale") }}:</span>
        <b-form-input v-model="koef" type="range" min="0" :max="maxScale" class="koef-range"></b-form-input>
      </span>
    </div>
  </div>
</template>

<script>
import {Chart, registerables} from 'chart.js'
import {chartColor} from "../config"
import {difficultyRatingService} from "../service/DifficultyRatingService"

Chart.register(...registerables)

export default {
  name: 'incomeChart',
  props: ['pools', 'updater', 'days'],
  data() {
    return {
      chart: null,
      koef: 10,
      max: 0,
      min: 99999,
      maxScale: 20,
      showDifficulty: false,
      difficulty: [],
      minDiff: 0,
      maxDiff: 0
    }
  },
  async mounted() {
    this.createChart()
  },
  watch: {
    async pools(value) {
      this.updateChart(value)
      if (this.showDifficulty) {
        if (!!this.difficulty || this.difficulty.length < this.days) {
          await this.getDifficulty(this.days)
        }
        this.chart.options.scales.y1 = this.getY1()
        this.updateChartDiff(value)
      }
    },
    koef(value) {
      this.updateChartKoef(+value)
    },
    async showDifficulty(value) {
      if (value) {
        if (!!this.difficulty || this.difficulty.length < this.days) {
          await this.getDifficulty(this.days)
        }
        this.chart.options.scales.y1 = this.getY1()
      } else {
        delete this.chart.options.scales.y1
      }
      this.updateChartDiff(value)
    }
  },
  methods: {
    async createChart() {
      let setLabels = new Set()
      this.pools.forEach(p => Object.keys(p.rates).forEach(k => setLabels.add(k)))
      let labels = Array.from(setLabels).sort((a, b) => Date.parse(a) - Date.parse(b))
      let datasets = []
      for (let pool of this.pools) {
        let datasetIncome = this.getDatasetIncome(pool, labels)
        datasets.push(datasetIncome)
      }
      if (this.showDifficulty) {
        let datasetDiff = this.getDatasetDiff(this.diffculty, labels)
        datasets.push(datasetDiff)
      }
      this.chart = new Chart(
          document.getElementById('incomeChart'),
          {
            data: {
              datasets: datasets,
              labels: labels
            },
            options: {
              responsive: true,
              plugins: {
                title: {
                  display: false,
                  text: this.$t("chart.title", {day: labels.length})
                },
                tooltip: {
                  callbacks: {
                    label: function (context) {
                      if (context.dataset.label == 'Difficulty') return context.dataset.label + ': ' + context.parsed.y + ' TH'
                      return context.dataset.label + ': ' + (context.parsed.y).toFixed(8) + ' BTC'
                    }
                  }
                }
              },
              interaction: {
                intersect: false,
                mode: 'index'
              },
              scales: {
                y: {
                  position: 'left',
                  display: true,
                  suggestedMin: this.min * ((100 - +this.koef) / 100),
                  suggestedMax: this.max * ((100 + +this.koef) / 100),
                  ticks: {
                    callback: function (value, index, ticks) {
                      return (value * 100000000).toFixed(0)
                    }
                  },
                  title: {
                    display: true,
                    text: 'BTC x 0.00000001'
                  },
                }
              }
            }
          }
      )
    },
    updateChart(pools) {
      let setLabels = new Set()
      pools.forEach(p => Object.keys(p.rates).forEach(k => setLabels.add(k)))
      let labels = Array.from(setLabels).sort((a, b) => Date.parse(a) - Date.parse(b))
      this.chart.data.datasets = []
      for (let pool of pools) {
        let datasetIncome = this.getDatasetIncome(pool, labels)
        this.chart.data.datasets.push(datasetIncome)
      }
      this.chart.data.labels = labels
      this.chart.options.plugins.title.text = this.$t("chart.title", {day: labels.length})
      this.updateChartKoef()
    },
    updateChartKoef(koef) {
      if (koef) this.koef = koef
      this.chart.options.scales.y.suggestedMin = this.min * ((100 - (this.maxScale - this.koef)) / 100)
      this.chart.options.scales.y.suggestedMax = this.max * ((100 + (this.maxScale - this.koef)) / 100)
      if (this.showDifficulty) {
        this.chart.options.scales.y1.suggestedMin = this.minDiff * ((100 - (this.maxScale - this.koef)) / 100)
        this.chart.options.scales.y1.suggestedMax = this.maxDiff * ((100 + (this.maxScale - this.koef)) / 100)
      }
      this.chart.update()
    },
    getDatasetIncome(pool, labels) {
      let color = +pool.pool_id > 0 ? chartColor[+pool.pool_id] : chartColor[0]
      let res = []
      labels.forEach(l => {
        if (pool.rates.hasOwnProperty(l)) res.push(pool.rates[l])
        else res.push(null)
      })
      let datasetIncome = {
        id: pool.pool_id,
        type: 'line',
        label: pool.pool_id == -1 ? "YOU" : pool.pool_name.toUpperCase(),
        data: res,
        backgroundColor: color,
        borderColor: color,
        yAxisID: 'y'
      }
      this.max = res.length > 0 ? Math.max.apply(null, res) : this.max
      this.min = res.length > 0 ? Math.min.apply(null, res) : this.min
      return datasetIncome
    },
    updateChartDiff(showDiff) {
      if (showDiff) {
        let setLabels = new Set()
        this.pools.forEach(p => Object.keys(p.rates).forEach(k => setLabels.add(k)))
        let labels = Array.from(setLabels).sort((a, b) => Date.parse(a) - Date.parse(b))
        console.log(this.difficulty)
        let diffData = labels.map(l => {
          if (this.difficulty[l]) return +((this.difficulty[l] / 1000000000000).toFixed(2))
          else return 0
        })
        console.log(diffData)
        this.maxDiff = Math.max.apply(null, diffData)
        this.minDiff = Math.min.apply(null, diffData)
        let datasetDiff = {
          id: 'diff',
          type: 'line',
          label: 'Difficulty',
          data: diffData,
          backgroundColor: 'rgba(201, 201, 201, 0.4)',
          borderColor: 'rgba(201, 201, 201, 0.4)',
          yAxisID: 'y1',
          radius: 0,
          fill: true
        }
        this.chart.data.datasets.push(datasetDiff)
        this.chart.options.scales.y1.suggestedMin = this.minDiff * ((100 - (this.maxScale - this.koef)) / 100)
        this.chart.options.scales.y1.suggestedMax = this.maxDiff * ((100 + (this.maxScale - this.koef)) / 100)
        console.log(this.maxDiff)
        console.log(this.minDiff)
        console.log('this.chart.options.scales.y1.suggestedMin', this.minDiff * ((100 - (this.maxScale - this.koef)) / 100))
        console.log('this.chart.options.scales.y1.suggestedMax', this.maxDiff * ((100 + (this.maxScale - this.koef)) / 100))
      } else {
        this.chart.data.datasets = this.chart.data.datasets.filter(d => d.id != 'diff')
      }
      this.chart.update()
    },
    async getDifficulty() {
      const response = await difficultyRatingService.get(this.days);
      if (response && response.difficulties) {
        this.difficulty = response.difficulties;
      } else {
        console.error("Invalid response format");
      }
    },
    getY1() {
      return {
        type: 'linear',
        display: true,
        position: 'right',
        title: {
          display: true,
          text: 'Difficulty, TH'
        }
      }
    }
  }
}
</script>

<style scoped>
.koef-range {
  width: 150px;
  margin-right: 15px;
  height: 12px
}
</style>