export function isMobile () {
  if (/Android|webOS|iPhone|iPad|iPod|BlackBerry|IEMobile|Opera Mini/i.test(navigator.userAgent)) {
    return true
  } else {
    return false
  }
}

export function formatDate (date) {
  let dd = date.getDate()
  if (dd < 10) dd = '0' + dd
  let mm = date.getMonth() + 1
  if (mm < 10) mm = '0' + mm
  let yy = date.getFullYear() % 100
  if (yy < 10) yy = '0' + yy
  return dd + '.' + mm + '.' + yy
}

export function monthByNum (num) {
  const arr = [
    'Январь',
    'Февраль',
    'Март',
    'Апрель',
    'Май',
    'Июнь',
    'Июль',
    'Август',
    'Сентябрь',
    'Ноябрь',
    'Декабрь'
  ]
  return arr[num]
}

export function convertHahs (hash) {
  let result = hash
  if (hash > 1000) result = hash / 1000 + ' kH'
  if (hash > 1000000) result = hash / 1000000 + ' MH'
  if (hash > 1000000000) result = hash / 1000000000 + ' GH'
  if (hash > 1000000000000) result = hash / 1000000000000 + ' TH'
  if (hash > 1000000000000000) result = hash / 1000000000000000 + ' PH'
  return result
}
