<template>
  <div class="container">
    <div class="row justify-content-center">
			<div class="col-12 text-center">
				<div class="section-title">
					<h4 class="title mb-4">{{ $t("roadmap.title") }}</h4>
				</div>
			</div>
		</div>
		<b-row v-if="!isMobile">
			<b-col lg="4" sm="12">
				<Release1 />
			</b-col>
			<b-col lg="4" sm="12">
				<Release2 />
			</b-col>
			<b-col lg="4" sm="12">
				<Release3 />
			</b-col>
		</b-row>
		<b-tabs v-else content-class="mt-1" card fill>
			<b-tab title="R 1.0" active>
				<Release1 />
			</b-tab>
			<b-tab title="R 2.0">
				<Release2 />
			</b-tab>
			<b-tab title="R 3.0">
				<Release3 />
			</b-tab>
		</b-tabs>
  </div>
  
</template>

<script>
import Release1 from "./release1.vue"
import Release2 from "./release2.vue"
import Release3 from "./release3.vue"
import { isMobile } from "../../utils"

export default {
  name: "Roadmap",
	components: {
		Release1,
		Release2,
		Release3
	},
	data () {
		return {
			slide: 0
		}
	},
	computed: {
		isMobile () {
			return isMobile()
		}
	}
}
</script>

<style scoped>
.card-footer {
	font-size: 0.7rem;
	font-style: italic;
	text-align: right;
}
</style>