<template>
  <div>
    <header
      id="topnav"
      class="defaultscroll sticky"
      :class="{ 'bg-white': isWhiteNavbar === true }"
    >
      <div class="container">
        <div>
          <router-link class="logo" to="/">
            <img src="images/logo.png" height="40" alt="" />
          </router-link>
        </div>
        <div class="lang-button" >
          <b-dropdown variant="transparent">
            <template #button-content>
              <img :src="getFlagImage()" height="20" alt="" />
              <span class="ml-2">{{ language.toUpperCase() }}</span>
            </template>
            <b-dropdown-item class="lang-item" @click="setLanguage('en')" :active="language === 'en'">
              <img src="images/flags/us_round.svg" height="20" alt="" />
              <router-link to="/en/" class="ml-2 link-green">EN</router-link>
            </b-dropdown-item>
            <b-dropdown-item class="lang-item" @click="setLanguage('ru')" :active="language === 'ru'">
              <img src="images/flags/ru_round.png" height="20" alt="" />
              <router-link  to="/ru/" class="ml-2 link-green">РУ</router-link>
            </b-dropdown-item>
            <b-dropdown-item class="lang-item" @click="setLanguage('ch')" :active="language === 'ch'">
              <img src="images/flags/ch_round.png" height="20" alt="" />
              <router-link  to="/ch/" class="ml-2 link-green">CH</router-link>
            </b-dropdown-item>
          </b-dropdown>
        </div>

        <div class="menu-extras">
          <div class="menu-item">
            <!-- Mobile menu toggle-->
            <a
              class="navbar-toggle"
              @click="toggleMenu()"
              :class="{ open: isCondensed === true }"
            >
              <div class="lines">
                <span></span>
                <span></span>
                <span></span>
              </div>
            </a>
          </div>
        </div>

        <div id="navigation">
          <ul
            class="navigation-menu"
            :class="{ 'nav-light': navLight === true }"
          >
            <li>
              <router-link to="/" class="side-nav-link-ref" v-scroll-to="'#top'">{{ $t("topnav.rating") }}</router-link>
            </li>
            <li>
              <router-link to="/" class="side-nav-link-ref" v-scroll-to="'#faq'">{{ $t("topnav.faq") }}</router-link>
            </li>
            <li>
              <router-link to="/" class="side-nav-link-ref" v-scroll-to="'#roadmap'">{{ $t("topnav.roadmap") }}</router-link>
            </li>
          </ul>
        </div>
      </div>
    </header>
  </div>
</template>

<script>
import { mapGetters, mapActions } from 'vuex'

export default {
  data() {
    return {
      isCondensed: false,
      locale: ''
    };
  },
  props: {
    isWhiteNavbar: {
      type: Boolean,
    },
    navLight: {
      type: Boolean,
    }
  },

  computed: {
    ...mapGetters(['language'])
  },

  mounted: () => {
    window.onscroll = function () {
      onwindowScroll();
    };

    function onwindowScroll() {
      let topnav = document.getElementById("topnav")
      let backtotop = document.getElementById("back-to-top")
      if (
        document.body.scrollTop > 50 ||
        document.documentElement.scrollTop > 50
      ) {
        if (topnav) document.getElementById("topnav").classList.add("nav-sticky");
      } else {
        if (topnav) document.getElementById("topnav").classList.remove("nav-sticky");
      }

      if (
        document.body.scrollTop > 100 ||
        document.documentElement.scrollTop > 100
      ) {
        if (backtotop) document.getElementById("back-to-top").style.display = "inline";
      } else {
        if (backtotop) document.getElementById("back-to-top").style.display = "none";
      }
    }

    var links = document.getElementsByClassName("side-nav-link-ref");
    var matchingMenuItem = null;
    for (var i = 0; i < links.length; i++) {
      if (window.location.pathname === links[i].pathname) {
        matchingMenuItem = links[i];
        break;
      }
    }

    if (matchingMenuItem) {
      matchingMenuItem.classList.add("active");
      var parent = matchingMenuItem.parentElement;

      /**
       * TODO: This is hard coded way of expading/activating parent menu dropdown and working till level 3.
       * We should come up with non hard coded approach
       */
      if (parent) {
        parent.classList.add("active");
        const parent2 = parent.parentElement;
        if (parent2) {
          parent2.classList.add("active");
          const parent3 = parent2.parentElement;
          if (parent3) {
            parent3.classList.add("active");
            const parent4 = parent3.parentElement;
            if (parent4) {
              const parent5 = parent4.parentElement;
              parent5.classList.add("active");
            }
          }
        }
      }
    }
  },
  methods: {
    ...mapActions(['setRuLanguage', 'setEnLanguage', 'setChLanguage']),
    toggleMenu() {
      this.isCondensed = !this.isCondensed;
      if (this.isCondensed) {
        document.getElementById("navigation").style.display = "block";
      } else document.getElementById("navigation").style.display = "none";
    },

    onMenuClick(event) {
      event.preventDefault();
      const nextEl = event.target.nextSibling.nextSibling;

      if (nextEl && !nextEl.classList.contains("open")) {
        const parentEl = event.target.parentNode;
        if (parentEl) {
          parentEl.classList.remove("open");
        }
        nextEl.classList.add("open");
      } else if (nextEl) {
        nextEl.classList.remove("open");
      }
      return false;
    },
    setLanguage (lang) {
      if (lang === 'ru') {
        this.setRuLanguage()
        this.$i18n.locale = 'ru'
        return
      }
      if (lang === 'en') {
        this.setEnLanguage()
        this.$i18n.locale = 'en'
      }
      if (lang === 'ch') {
        this.setChLanguage()
        this.$i18n.locale = 'ch'
      }
    },
    getFlagImage () {
      if (this.$i18n.locale === 'en') return "/images/flags/us_round.svg"
      if (this.$i18n.locale === 'ru') return "/images/flags/ru_round.png"
      if (this.$i18n.locale === 'ch') return "/images/flags/ch_round.png"
    }
  },
};
</script>

<style scoped>
 .lang-button {
  float: right;
  line-height: 68px;
 }
 .lang-item {
  line-height: 24px;
 }
 .link-green {
  color: green;
 }
</style>
