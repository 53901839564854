import {config} from '../config'
import {getRequestOptions, handleResponse} from '@/service/UtilsService'

export const ratingService = {
    top,
 //   update,
//   incomeHistory,
    totalPools
}

function top(period, unit, userWatcherId) {
    const url = new URL(config.api.rankapi.top)
    url.searchParams.set('period', period)
    url.searchParams.set('unit', unit)
    if (userWatcherId) url.searchParams.set('watcher_id', userWatcherId)
    return fetch(url, getRequestOptions()).then(handleResponse)
}

function update() {
    const url = new URL(config.api.rankapi.update)
    return fetch(url, getRequestOptions()).then(handleResponse)
}

function incomeHistory(poolId, period) {
    const url = new URL(config.api.rankapi.incomeHistory)
    url.searchParams.set('id', poolId)
    url.searchParams.set('period', period)
    return fetch(url, getRequestOptions()).then(handleResponse)
}

function totalPools() {
    const url = new URL(config.api.rankapi.totalPools)
    return fetch(url, getRequestOptions()).then(handleResponse)
}
