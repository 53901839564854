export const domain = process.env.NODE_ENV === 'production' ? 'https://poools.top/api' : 'http://localhost:9000/api'

export const config = {
    updateTimeSec: 60 * 5,
    api: {
        rankapi: {
            top: domain + '/rank/pools/top/by-period',
            update: domain + '/rank/update',
            incomeHistory: domain + '/income/last',
            totalPools: domain + '/rank/pools/all'
        },
        watcherapi: {
            add: domain + '/watcher/add'
        },
        difficultyapi: {
            get: domain + '/difficulty/by-period'
        }
    }
}

export const chartColor = [
    '#32a852', '#3298a8', '#3a32a8', '#a2a832', '#a83263', '#03abff', '#ff03f2', '#ff039a', '#ff0329', '#ffb803', '##876610', '#d7b5e6'
]
