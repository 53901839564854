<template>
  <b-card class="m-auto"
    title="Release 2.0"
    :footer="$t('roadmap.completed', {persent: 30})" 
  >
    <hr />
    <b-card-text>
      <ul>
        <li>{{ $t('roadmap.release2.p1') }}</li>
        <li>{{ $t('roadmap.release2.p2') }}</li>
        <li>{{ $t('roadmap.release2.p3') }}</li>
        <li>{{ $t('roadmap.release2.p4') }}</li>
        <li>{{ $t('roadmap.release2.p5') }}</li>
        <li>{{ $t('roadmap.release2.p6') }}</li>
        <li>{{ $t('roadmap.release2.p7') }}</li>
      </ul>
    </b-card-text>
  </b-card>
</template>

<script>
export default {
  name: "Release2"
}
</script>

<style>

</style>