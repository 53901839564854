export function authHeader () {
  return { Authorization: 'Bearer eyJhbGciOiJIUzI1NiJ9.eyJyb2xlIjpbeyJhdXRob3JpdHkiOiJST0xFX1VTRVIifV0sImlkIjoyLCJzdWIiOiJ1c2VyIiwiaWF0IjoxNzE2NzYzNjk1LCJleHAiOjE3NDgyOTk2OTV9.cGXn2G0J1lOfZC25od2eb-y2Dd0yRlNoi7oTfRQp_U4' }
}

export function getRequestOptions () {
  return {
    method: 'GET',
    headers: {
      ...authHeader(),
      'Content-Type': 'application/json',
      'Access-Control-Allow-Origin': '*'
    }
  }
}

export function postRequestOptions (params) {
  return {
    method: 'POST',
    headers: {
      ...authHeader(),
      'Content-Type': 'application/json',
      'Access-Control-Allow-Origin': '*'
    },
    body: JSON.stringify(params)
  }
}

export function handleResponse (response) {
  return response.text().then(text => {
    const data = text && JSON.parse(text)
    if (!response.ok) {
      if (response.status === 401) {
        logout()
        location.reload(true)
      }
      const error = (data && data.message) || response.statusText
      return Promise.reject(error)
    }

    return data
  })
}
